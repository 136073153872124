.AuthPage {
    
    &__in {
        padding: 10px 20px;
        padding-top: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // height: 100vh;
        min-height: 100vh;
    }

    &__body {
        max-width: 485px;
        width: 100%;

        &_title {
            text-align: center;
            margin-bottom: 40px;
            font-weight: 600;
            font-size: 35px;
            line-height: 45px;
        }

        &_form {

            &_item {
                margin-bottom: 25px;
            }

            &_action {
                margin-top: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                
            }
        }
    }
}
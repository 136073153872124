.OrderExList {

    &__name {
        margin-bottom: 15px;
        color: #989898;
        font-weight: 600;
    }

    &__list {

        &_item {
            margin-bottom: 15px;
        }
    }
}
.OrgsPage {

    &__body {
        width: 100%;
        

        &_list {
            display: flex;
            flex-wrap: wrap;
            margin-left: -20px;
            margin-right: -20px;
        }

        &_item {
            width: calc((100% / 3) - 40px);
            margin: 0 20px;
            margin-bottom: 40px;
        }
    }
}
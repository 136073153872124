.wrapper {
  padding: 10px 15px;
  background-color: #F3F3F3;
  border-radius: 12px;

  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: normal;
    height: 100%;
    display: flex;
    align-items: center;
  } 

  .delete {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F7D9DE;
    color: #DC5E73;
    font-size: 20px;
    line-height: 20px;
    transition: all .2s ease;
    border: none;
    cursor: pointer;
    margin: 0 0 0 auto;

    &:active {
      transform: scale(0.9);
    }

    &:hover {
      color: #fff;
      background-color: red;
    }
  } 

  .main {
    background-color: #fff;
    padding: 15px;
    border-radius: 12px;

    .text {

    }

    .size {
      .size_item {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        

        &:nth-child(1) {
          .size_text {
            font-size: 21px;
          }
        }

        &:nth-child(2) {
          .size_text {
            font-size: 16px;
          }
        }

        &:nth-child(3) {
          .size_text {
            font-size: 14px;
          }
        }

        &:nth-child(3) {

        }

        &:last-child {
          margin-bottom: 0;
        }

        .size_icon {
          width: 20px;
          height: 20px;
          flex: 0 0 20px;
          border-radius: 100%;
          border: 2px solid rgba(42, 42, 42, 0.40);
          position: relative;
          margin-right: 10px;

          &:after {
            position: absolute;
            width: 10px;
            height: 10px;
            top: calc(50% - (10px / 2));
            left: calc(50% - (10px / 2));
            content: '';
            background-color: #2A2A2A;
            transition: all .2s ease;
            transform: scale(0);
            border-radius: 100%;
          }
        }

        .size_text {
          font-weight: 600;
          line-height: normal;
          // font-family: 'Gilroy';
        }
      }

      .size_item.active {
        .size_icon {
          border-color: #2A2A2A;

          &:after {
            transform: scale(1);
          }
        }
      }

    }

    .colors {
      display: flex;
      overflow-x: auto;
      margin-left: -4px;
      margin-right: -4px;

      .color {
        height: 40px;
        border-radius: 100%;
        flex: 0 0 40px;
        border: 4px solid rgba(0,0,0, .1);
        margin: 0 4px;
        cursor: pointer;
        transition: all .2s ease;

        &:hover {
          border-color: var(--violet);
        }
      }

      .color.active {
        border-color: var(--violet);
      }
    }
  }
}

.colorPicker {
  margin-left: 4px;
  position: relative;
  cursor: pointer !important;

  &__input {
    cursor: pointer !important;
    -webkit-appearance: none;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 100%;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    &::-webkit-color-swatch {
      border-radius: 100%;
    }
  }

  &__icon {
    cursor: pointer !important;

    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 9px;
  }
}
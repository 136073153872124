.TablePag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__in {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__side {
        width: 32px;
        height: 32px;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: unset;
        border: 1px solid #eaeaea;
        background-color: #fff;
        margin: 0 7px;
        color: var(--violet);
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
            border-color: #1890ff;
            color: #1890ff;
        }
    }


    &__side.TablePag__side-start {}
    &__side.TablePag__side-end {}
}
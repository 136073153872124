.DishItem {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
    border-radius: 10px;
    padding: 10px;

    &__img {
        height: 100px;
        width: 100px;
        border-radius: 10px;
        overflow: hidden;
        flex: 0 0 auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__body {
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &_name {
            font-weight: 500;
            color: #666;    
        }

        &_price {
            color: var(--red);
            font-weight: 600;
        }
    }
}
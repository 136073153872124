.gs-datepicker {

    .react-datepicker-wrapper {
        .react-datepicker__input-container {
            box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
            border-radius: 10px;
            input {
                padding: 15px 20px;
                border: none;
                background-color: #fff;
                border-radius: 10px;
                color: var(--violet);
                font-weight: 600;
                outline: none;
                transition: all .3s ease;
                border: 2px solid transparent;width: 100%;

                &:focus {
                    border-color: var(--violet);
                }

                &::placeholder {
                    color: var(--light_violet);
                    font-weight: 600;
                }
            }
        }
    }

    .react-datepicker__tab-loop {
        

        .react-datepicker-popper {

            .react-datepicker {
                border-radius: 10px;
                overflow: hidden;
                background-color: #fff;
                border: none;
                padding: 25px;
                font-family: 'Montserrat';


                .react-datepicker__navigation {
                    top: 47px;
                }

                .react-datepicker__navigation--previous {
                    left: 50px;
                    
                    span {
                        &::before {
                            border-color: var(--violet);
                        }
                    }
                }

                .react-datepicker__navigation--next {
                    right: 50px;

                    span {
                        &:before {
                            border-color: var(--violet);
                        }
                    }
                }

                .react-datepicker__month-container {

                    .react-datepicker__header  {
                        background-color: #fff;
                        border: none;
                        padding: 20px 0;
                        
                        .react-datepicker__current-month {
                            color: var(--violet);   
                            text-transform: capitalize !important;
                            font-weight: 500 !important;
                        }

                        .react-datepicker__day-names {
                            font-weight: 600;
                            color: var(--violet);   
                            color: #666 !important;
                            text-transform: capitalize !important;

                            .react-datepicker__day-name {
                                color: #7B7B7B;
                            }
                        }
                    }

                    .react-datepicker__month {

                        .react-datepicker__day {
                            color: #7B7B7B;
                            font-weight: 600;
                        }

                        .react-datepicker__day.react-datepicker__day--today {
                            color: #7B7B7B;
                            background-color: unset;
                        }

                        .react-datepicker__day.react-datepicker__day--selected {
                            background-color: var(--violet) !important;
                            color: #fff !important;
                        }

                        .react-datepicker__day.react-datepicker__day--outside-month {
                            color: #D7D7D7;
                        }
                    }
                }
            }
        }
    }
}
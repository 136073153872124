.OrderItem {
    background: #FFFFFF;
    box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;

    &__head {
        margin-bottom: 20px;
        font-weight: 600;
    }

    &__body {
        flex: 1 0 auto;
        padding-bottom: 20px;

        &_item {
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 19px;
            &:last-child {
                margin-bottom: 0;
            }

            &_name {
                
                margin-bottom: 5px;
                color: #666;
            }

            &_value {

            }
        }
    }
    &__price {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 600;
        
    }
}
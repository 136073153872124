.Text {
    border-radius: 10px;
    background-color: #fff;
    padding: 15px 20px;
    display: block;
    width: 100%;
    resize: none;
    color: var(--violet);
    font-weight: 600;
    transition: all .3s ease;
    border: 1px solid transparent;
    outline: none;
    position: relative;
    height: 150px;
    &::placeholder {
        color: var(--light_violet);
    }

    &:focus {
        border-color: var(--light_violet);
    }
}

.TextWrap {
    width: 100%;
    position: relative;

    &__label.valid {
        transform: translateY(-12px) translateX(15px);
        width: auto;
        background-color: #fff;
        height: auto;
        padding: 5px 5px 5px 5px;
        border-radius: 10px;
        font-size: 12px;    
        line-height: 12px;
    }

    &__label {
        z-index: 3;
        position: absolute;
        top: 0;
        left: 0;
        // width: 100%;
        height: 100%;
        display: flex;
        padding: 15px 20px;
        transition: all .3s ease;
        font-weight: 600;
        color: var(--light_violet);
    }
}

.Text.shadow {
    box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
}
.MiniPlate {
    height: 150px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    user-select: none;

    &__img {
        pointer-events: none;
        touch-action: none;
        overflow: hidden;
        flex: 0 0 100px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__body {
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &_name {
            color: #666;
            font-weight: 500;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
            line-height: 14px;
            
        }

        &_prices {
            font-weight: 600;

            &_actual {
                color:#FC5834;
            }

            &_main {
                color: #666;
                text-decoration: line-through;
            }
        }
    }
}
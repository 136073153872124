.CreateSubcategory {

    &__upload {
        position: relative;

        &_btn {
            z-index: 10;
            border: none;
            transition: all .3s ease;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            line-height: 20px;
            color: #fff;
            background-color: var(--red);
            border-radius: 100%;
            position: absolute;
            top: -15px;
            right: -15px;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }

            &:active {
                transform: scale(0.95);
            }
        }
    }
}
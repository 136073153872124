.AddMod {

    &__head {

    }

    &__body {

        &_item.active {

            .AddMod__body_item_name, .AddMod__body_item_value {
                // border-color: var(--light_violet);
            }

           
        }
        &_item.noshadow {
            box-shadow: unset;
        }
        &_item {
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 15px;
            box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);

            width: 100%;

            &_main {
                padding: 0;

                &_image {
                    height: 250px;

                    border-radius: 10px;
                    box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
                    position: relative;


                    &_delete {
                        width: 45px;
                        height: 45px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        position: absolute;
                        top: -10px;
                        right: -10px;
                        background-color: var(--red);
                        transition: all .3s ease;
                        cursor: pointer;
                        border: none;
                        font-size: 25px;
                        line-height: 25px;
                        &:hover {
                            opacity: .8;
                        }

                        &:active {
                            transform: scale(0.9);
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        display: flex;
                    }
                }
            }

            &_name {
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;
                border: none;
                outline: none;
                padding: 10px 15px;
                font-weight: 600;
                color: var(--violet);
                border-radius: 10px;
                border: 1px solid transparent;
                transition: all .3s ease;

                &::placeholder {
                    color: var(--light_violet);
                }
            }

            &_value {
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;
                border: none;
                outline: none;
                padding: 10px 15px;
                font-weight: 600;
                color: var(--violet);
                border-radius: 10px;
                // border: 1px solid transparent;
                transition: all .3s ease;

                &::placeholder {
                    color: var(--light_violet);
                }
            }

            &_img {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 auto;
                height: 100%;
                
                &_el {
                    width: 30px;
                    height: 30px;
                    border-radius: 5px;
                    overflow: hidden;
                    // border: 1px solid var(--violet);
                    background-color: bisque;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: flex;
                    }
                }
            }

            &_action {
                .Button {
                    width: 100%;
                }
            }
        }
    }
}




.RoleLimit {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
  padding-top: 130px;
  width: 100%;

  span {
    color: green;
  }
}
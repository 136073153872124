.SelectOrg {

    &__body {
        max-height: 250px;
        overflow-y: auto;
        padding: 30px 30px;
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 30px;

        &_item {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__action {

        .Button {
            width: 100%;    
        }
    }
}
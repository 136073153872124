.Sidebar-pl.hide {
    width: 70px;
}

.Sidebar.hide {
    width: 70px;

    .Sidebar__head {
        // justify-content: flex-start;
        &_label {
            width: 0;
        }
        &_icon {
            transform: rotate(180deg);
        }
    }
    .SidebarItem__main_label {

        &_name {
            display: none;
        }
    }
    .SidebarItem__main_dropicon {
        display: none;
    }
}
.Sidebar-pl {
    width: 355px;
    flex: 0 0 auto;
    transition: width .3s ease;
}
.Sidebar {
    width: 355px;
    height: 100%;
    background-color: #fff;
    flex: 0 0 auto;
    position: fixed;
    padding: 22px 0;
    bottom: 0;
    left: 0;
    height: calc(100% - 80px);
    overflow-y: scroll;
    // padding-top: calc(25px + 80px);
    overflow-x: hidden;
    transition: width .3s ease;


    &__head {
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        text-transform: uppercase;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 29px;
        transition: all .3s ease;
        position: relative;

        &_label {
            overflow: hidden;
            width: 71px;
            transition: all .3s ease;
        }

        &_icon {
            color: var(--violet);
            cursor: pointer;
            font-size: 25px;
            line-height: 25px;
            transition: all .3s ease;
        }
    }

    &__list {

    }

    
}
.MiniStorie {
    height: 90px;

    &__img {
        border-radius: 10px;
        overflow: hidden;
        pointer-events: none;
        height: 100%;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
            user-select: none;
        }
    }
}
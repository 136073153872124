.GridToggle {
    padding: 20px;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;

    &__in { 
        display: flex;
        align-items: center;
    }

    &__item {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: #fff;
        transition: all .3s ease;
        box-shadow: 0px 0px 20px rgba(123, 153, 255, 0.5);
        font-size: 30px;
        line-height: 20px;
        color: var(--violet);
        cursor: pointer;
        border: none;
        margin: 0 5px;

        &:active {
            transform: scale(0.95);
        }

        &:hover {
            opacity: .8;
        }

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

}
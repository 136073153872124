.StatChart {
    height: 510px;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 30px 30px 30px;
    // max-width: 100%;
    // overflow-x: hidden;
    &__top {
        
    }
    
    &__chart {
        // border: 1px solid red; 
        border-radius: 10px;
        height: 100%;
        // overflow-x: auto;
        max-width: 1100px;
        width: 100%;
        overflow-x: auto;
        
        
        

        &_el {
            padding-bottom: 30px;
            // height: 100% !important;
            // width: 100% !important;
            // height: 100% !important;
            width: 100% !important;
        }
    }
}
.TimeSelect {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__item {
        display: flex;
        width: 48%;
        padding: 20px 30px;
        background-color: #fff;
        border-radius: 20px;
        cursor: pointer;
        margin-bottom: 15px;
        justify-content: space-between;

        &_name {
            color: var(--light_violet);
            text-transform: uppercase;
            font-weight: 600;
        }

        

        &_vals {

            &_item {
                font-weight: 600;
            }
            
        }
    }

}

.TimeSelect.shadow {

    .TimeSelect__item {
        box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
    }
}
.gs-table {
    width: 100%;
    position: relative;
    border-radius: 15px;
    overflow: hidden;

    &__load {
        
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#fff, .5);
        display: flex;
        padding: 50px 30px;
        justify-content: center;
        align-items: flex-start;
        z-index: 10;
    }

    &__head {
        display: flex;
        align-items: center;
        cursor: pointer;


        &_label {
            margin-right: 10px;
        }
        &_icon {
            transition: all .3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
        }
    }

    &__head.active {

        .gs-table__head_icon {
            // transform: rotate(180deg);
            opacity: 1;
        }
    }

    &__head.asc {
        .gs-table__head_icon {
            transform: rotate(180deg);
        }
    }


    &-status {
        color: #898989;
        font-weight: 600;
    }

    &-status.gs-table-status-new {
        color: #30B42E;
    }

    &-status.gs-table-status-notpay {
        color: var(--red);
    }

    &-status.gs-table-status-process {
        color: var(--violet);
    }

    .spacer {
        margin-bottom: 15px;
    }

    tr.row.active {
        td {
            color: #fff;
            background-color: var(--violet) !important;
        }
    }


    tr.row {
        cursor: pointer;

        td {
            transition: all .3s ease;
        }

        &:nth-child(even) {
            td {
                background-color: #F6F8FF;
            }
        }

    }
    tr {

        td, th {
            // pointer-events: none;
            
        }

        
        &:first-child {
            
        }

        &:nth-child(3) {

            td {
                
                
                &:first-child {
                    border-radius: 15px 0 0 0;
                }

                &:last-child {
                    border-radius: 0 15px 0 0 ;
                }
            }
        }
        &:last-child {

            td {
                &:first-child {
                    border-radius: 0 0 0 15px;
                }

                &:last-child {
                    border-radius: 0 0 15px 0;
                }
            }
        }

        th {
            font-weight: 600;
            color: #000;
            padding: 20px 10px;
            background-color: #fff;
            text-align: left;
            
            
            &:first-child {
                border-radius: 15px 0 0 15px;
            }
            

            &:last-child {
                border-radius: 0 15px 15px 0;
            }

        }

        td {
            background-color: #fff;
            padding: 15px 10px;
            color: rgba(0, 0, 0, 0.5);
            font-weight: 600;

            &:nth-child(1) {
                width: 10.6%;
            }
            &:nth-child(2) {
                width: 16%;
            }
            &:nth-child(3) {
                width: 17.7%;
            }
            &:nth-child(4) {
                width: 11.3%;
            }
            &:nth-child(5) {
                width: 13.5%;
            }
            &:nth-child(6) {
                width: 16.6%;
            }
            &:nth-child(7) {
                width: 14.3%;
                // width: 210px !important;
                white-space: nowrap;
               
            }
        }
    }
}
.OrderInfo {
    position: relative;

    

    &__main {
        &:first-child {
            box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
        }
        &_item {
            margin-bottom: 20px;
            font-weight: 600;

            &_name {
                margin-bottom: 5px;
                color: #989898;
            }

            &_value {
                color: var(--violet);
            }
        }

        
    }
}
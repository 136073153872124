.wrapper {
  .main {
    display: flex;
    overflow-x: auto;
    padding-bottom: 20px;
    // height: 401px;

    .main_add {
      // height: 418px;
      height: 635px;
      margin: 0 15px;
      // flex: 0 0 350px;
      flex: 0 0 auto;
      width: 350px;
      border-radius: 14px;
      background-color: #F8F8F8;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #989898;
      font-size: 15px;
      font-weight: 600;
      line-height: normal;
      padding: 60px;
      cursor: pointer;
      transition: all .2s ease;
      
      &:active {
        transform: scale(0.95);
      }
    }

    .main_item {
      margin: 0 15px;
    }
  }
}
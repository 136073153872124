.Settings {
    position: relative;

    &__load {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#fff, .5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        opacity: 0;
        transition: all .3s ease;
    }

    &__load.active {
        opacity: 1;
        z-index: 3;
    }

    &__body {
        
        &_selects.selected {
            align-items: flex-start;
            justify-content: space-between;
        }
        &_selects {
            border-radius: 10px;
            box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
            padding: 20px;
            height: 230px;
            display: flex;
            align-items: center;
            margin-top: 30px;

            &_pl {
                color: var(--light_violet);
                font-weight: 600;
                text-align: center;
                width: 100%;
                
                span {
                    width: 25px;
                    height: 25px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    color: #fff;
                    background-color: var(--violet);
                    font-size: 15px;
                    line-height: 15px;
                    
                }
            }  

            &_label {
                font-weight: 600;
                color: var(--light_violet);
            }

            &_item {
                width: 190px;
                height: 190px;
                border-radius: 10px;
                overflow: hidden;
                position: relative;
                &_btn {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    border: none;
                    transition: all .3s ease;
                    cursor: pointer;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    background-color: var(--red);
                    
                    &:active {
                        transform: scale(0.95);
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    display: flex;
                }
            }
            
            &_dn {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                &_label {
                    padding-right: 20px;
                    font-weight: 600;
                    
                }

                &_item {
                    width: 200px;
                    height: 200px;
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}
.wrapper.selected {
    border-color: var(--violet);
}

.wrapper {
    height: 260px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    padding: 3px;
    border: 3px solid transparent;
    transition: all .3s ease;
    
    &:hover {

        .StorieItem__add {
            opacity: 1;
            z-index: 2;
        }
    }

    &__add.StorieItem__add-danger {
        background-color: var(--red);
    }
    
    &__add {
        width: 40px;
        height: 40px;
        background-color: var(--violet);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        color: #fff;
        position: absolute;
        z-index: -1;
        transition: all .3s ease;
        bottom: 10px;
        right: 10px;
        opacity: 0;
        border: none;
        cursor: pointer;

        &:active {
            transform: scale(0.95);
        }
    }

    &__main {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;

    }

    &__img {
        width: 100%;
        height: 100%;
        pointer-events: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
            user-select: none;
        }
    }
    
}
.SubCard {    
    display: flex;
    flex-direction: column;
    height: 260px;
    justify-content: space-between;

    user-select: none;

    &__main {
        background-color: #fff;
        border-radius: 10px;
        // height: 100%;
        overflow: hidden;
        flex: 1 0 auto;
    }

    &__img {
        height: 150px;
        overflow: hidden;
        flex: 0 0 auto;
        pointer-events: none;
        touch-action: none;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__name {
        padding: 15px 20px;
        color: #666666;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
    }

    &__action {
        padding-top: 10px;
        .Button {
            width: 100%;
        }
    }
}
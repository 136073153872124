.PicItem {
    height: 200px;
    width: 200px !important;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
    flex: 0 0 auto;

    &__img.hidden {
        // opacity: .5;
        filter: grayscale(1);
    }

    &__img {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: flex;
        }
    }

    &__action {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    &__hide {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: var(--violet);
        font-size: 30px;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        transition: all .3s ease;
        border: none;
        color: #fff;
        line-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:active {
            transform: scale(0.95);
        }
    }
}
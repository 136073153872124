.ProfileMenu {
    background: #FFFFFF;
    box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.3);
    border-radius: 21px 21px 21px 21px;
    overflow: hidden;
    &__item {
        padding: 10px 20px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: 500;
        white-space: nowrap;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
            background-color: var(--light_gray);
        }
    }

    &__item.danger {
        color: var(--red);
    }
}
.MapPolygon {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    
    &__delete {
        position: absolute;
        top: 10px;
        right: 60px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--red);
        color: #fff;
        font-size: 18px;
        line-height: 18px;
        z-index: 10;
        border-radius: 100%;
        border: none;
        cursor: pointer;
        transition: all .3s ease;

        &:hover {
            opacity: .6;
        }

        &:active {
            transform: scale(0.95);
        }
        &:disabled {
            pointer-events: none;
            opacity: .6;
        }
    }
}
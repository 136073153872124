.Mod {
    width: 100%;

    &__head {
        
    }

    &__body {

        margin-bottom: 15px;

        &_action {
            margin-bottom: 15px;

        }

        &_name {
            font-weight: 600;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            color: #7B99FF;
            margin-bottom: 10px !important;

        }

        &_list {
            margin-top: 10px;

            &:last-child {
                .Mod__body_name {
                    margin-bottom: 0;
                }
            }
        }

        &_item {
            // display: flex;
            // align-items: center;
            // justify-content: space-between;
            border-radius: 10px;
            box-shadow: 0px 0px 41px rgba(123, 153, 255, 0.15);
            padding: 15px 20px;
            margin-bottom: 15px;
            font-weight: 600;

            &:last-child {
                margin-bottom: 0;
            }

            &_name {
                display: flex;
                align-items: center;
                height: 100%;
            }

            &_value {
                display: flex;
                align-items: center;
                height: 100%;
            }

            &_img {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                &_el {
                    width: 30px;
                    height: 30px;
                    border-radius: 5px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: flex;
                    }
                }
            }
            
        }
    }

    &__add {
        
    }
}
.UploadKml {

    input {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        z-index: -1;
        pointer-events: none;
    }


    &__label {
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: flex-end;

        &:hover {
            .UploadKml__label_text {
                color: var(--violet);
            }
            .UploadKml__label_icon {
                background-color: var(--light_violet);

            }
        }

        &_text {
            // color: var(--gray);
            transition: all .3s ease;
            margin-right: 10px;
            margin-bottom: 0;
        }

        &_icon {
            width: 35px;
            height: 35px;
            color: #fff;
            font-size: 15px;
            left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--violet);
            transition: all .3s ease;
            border-radius: 10px;
        }
    }
}
.CatalogCategoryPage {

    &__body {
        &_list {
            &_add {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                
            }
        }
    }
}
@font-face {
    font-family: "Nekst";
    src: url(../../assets/fonts/Nekst-Light.otf);
    font-weight: 400;
}
@font-face {
    font-family: "Nekst";
    src: url(../../assets/fonts/Nekst-Regular.otf);
    font-weight: 500;
}
@font-face {
    font-family: "Nekst";
    src: url(../../assets/fonts/Nekst-Bold.otf);
    font-weight: 600;
}

.nekst-font {
    font-family: "Nekst", sans-serif;
}

* {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    font: inherit;
}

ul, li {
    margin: 0;
    padding: 0;
}


li {
    list-style: none;
}
html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

body {
    width: 100%;
    height: 100%;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 18px;
    //font-weight: normal;
    color: var(--violet);
    background-color: var(--bg);
    overflow-x: hidden !important;
    // font-display: swap;
    // -webkit-font-smoothing: antialiased;
}
#root {
    // height: 100%;
    display: flex;
    overflow-x: hidden;

}
.page {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-x: hidden;

    .Header {

    }

    .Main {
        flex: 1 0 auto;
        height: 100%;
    }
}

.pageBody {
    display: flex;
    height: 100%;
    padding-top: 80px;
    overflow-x: hidden;
}

.pageBody-content {
    padding: 20px;
    width: 100%;
    overflow-x: hidden;
}


.def-label {
    font-weight: 600;
    color: #989898;
    margin-bottom: 10px;
}

.part {
    width: 100%;
}

.row-custom {
    margin-bottom: 20px;
}
.def-label {
    font-size: 16px;
    line-height: 19px;
    color: #989898;
}
.def-value {
    font-size: 16px;
    line-height: 19px;
    color: var(--violet);
    font-weight: 600;
}

.draggable {
    border: 2px solid transparent;
    transition: all .3s ease;
    border-radius: 10px;

    // &:hover {
    //     border-color: var(--light_violet);
        
    // }
}

.draggable.dragStart {
    opacity: 0 !important;
    
}

.draggable.dragOver {
    border-left-color: var(--light_violet);
    border-style: dashed !important;
    opacity: .5;
}


.ddd {
    // height: 1000px;
    display: flex;


    &__item {
        padding: 10px;
    }

    &__item.ddd__item-ds {
        
    }

}



